import moment from 'moment'

export const platformType = {
  TAOBAO: '淘宝/天猫',
  ALIBABA: '阿里巴巴',
  PDD: '拼多多',
  JD: '京东',
  DOUYIN: '抖音',
  XHS: '小红书',
  KUAISHOU: '快手',
  OTHER: '其他'
}
export const billType = {
  RECHARGE: '充值',
  EXPENSE: '消费',
  INCOME: '收入',
  WITHDRAW: '提现',
  MANUAL: '管理员转账',
  REFUND: '退款',
  REFUND2: '支付退回'
}
export const noticeType = {
  SYSTEM: '系统升级',
  WAREHOUSE: '仓库快递',
  PRITEST: '内测邀请'
}
export const roleCodeToName = {
  FORWARDER: { name: '代发', id: 3 },
  ADMIN: { name: '管理员', id: 4 },
  OEM: { name: '贴牌', id: 5 },
  FINANCE: { name: '财务', id: 6 },
  CUSTOMER: { name: '客服', id: 7 }
}
export const orderStatus = {
  UNPAID: '待支付',
  IN: '打单中',
  SUCCESS: '已出单',
  CANCEL: '取消',
  ABNORMAL: '异常',
  CLOSE: '关闭'
}
export const problemStatus = {
  0: '全部',
  BUY: '待您处理',
  ADMIN: '正在为您处理',
  SUCCESS: '已完成',
  CANCEL: '已关闭'
}
export const customerVisitObj = {
  YES: '已回访',
  NO: '未回访',
  CONTINUE: '继续跟进'
}
/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

// 时间格式化
export function formatDate (data) {
  data = parseFloat(data)
  let momentNum = ''
  if (data && data.toString().length === 10) {
    data = data * 1000
  }
  if (data && moment(data).isValid()) {
    momentNum = moment(data).format('YYYY-MM-DD HH:mm:ss')
  }
  return momentNum
}
export function timestampToTime (data) {
  data = parseFloat(data)
  let momentNum = ''
  if (data && data.toString().length === 10) {
    data = data * 1000
  }
  if (data && moment(data).isValid()) {
    momentNum = moment(data).format('YYYY-MM-DD')
  }
  return momentNum
}
export function uuid () {
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 *  (key-value,value-key)
 * @param json
 * @returns {Array}
 */
export function jsonToArray (json) {
  const array = []
  for (const key in json) {
    array.push({ name: json[key], value: key })
  }
  return array
}
/**
 *  获取当前时间
 *
 * @return 返回时间类型 yyyy-MM-dd HH:mm:ss
 */
export function sysTime () {
  const myDate = new Date()
  return (
    myDate.getFullYear() +
    '-' +
    getMakeZero(myDate.getMonth() + 1) +
    '-' +
    getMakeZero(myDate.getDate()) +
    ' ' +
    getMakeZero(myDate.getHours()) +
    ':' +
    getMakeZero(myDate.getMinutes()) +
    ':' +
    getMakeZero(myDate.getSeconds())
  )
}

/**
 *  时间补0
 *
 * @return 返回时间类型 yyyy-MM-dd HH:mm:ss
 */
function getMakeZero (s) {
  return s < 10 ? '0' + s : s
}
// 淘宝绑定去重
export function arrNewSet (arr) {
  for (var i = 0; i < arr.length; i++) {
    var item = arr[i]
    for (var j = i + 1; j < arr.length; j++) {
      if (
        ['TAOBAO', 'FW199-TAOBAO'].indexOf(item.type) > -1 &&
        ['TAOBAO', 'FW199-TAOBAO'].indexOf(arr[j].type) > -1 &&
        item.shopName == arr[j].shopName
      ) {
        if (item.type == 'TAOBAO') {
          arr.splice(i, 1)
          i--
        } else {
          arr.splice(j, 1)
          j--
        }
      }
    }
  }
  return arr
}

// 加密替换
/**
 *
 * @param {string} name
 * @param {'##' | '$$'} type
 * @param {string} replaceWord
 * @returns String
 */
export const sreReplace = (name, type, replaceWord) => {
  if (type === '##') {
    return name.replaceAll(/##.*?##/g, replaceWord)
  }
  if (type === '$$') {
    return name.replaceAll(/\$\$.*?\$\$/g, replaceWord)
  }
  return name
}


/** img src 替换 */
export const replaceImage = (target) => {
  return target.replace(/http\:\/\/cache.wodh.cn|http\:\/\/cache.cz01.cn/, 'https://czkj-cache.oss-cn-shenzhen.aliyuncs.com')  
}